import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'

const NotFoundPage = () => (
  <Layout>
    <SEO title="404: Not found" />
    <div className="ml-g4 mr-g4">
      <h1 className="mb-8">NOT FOUND</h1>
      <p>Page does not exist</p>
    </div>
  </Layout>
)

export default NotFoundPage
